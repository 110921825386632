import api from '../../services/api';
import { IGroup } from '../../interfaces/interfaces';
import { toast } from 'react-toastify';
import { GroupStatus } from '../../enums/enums';

export enum GroupActionTypes {
  CREATE = 'GROUP_CREATE',
  FETCH = 'GROUP_FETCH',
  FETCH_ACTIVE = 'GROUP_FETCH_ACTiVE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE_GROUP'
}

// Create cohort
export function createGroup(group: IGroup) {
  return function (dispatch) {
    return api
      .post('/groups', group)
      .then(({ data }) => {
        dispatch({
          type: GroupActionTypes.CREATE,
          payload: data
        });
        toast.success('Successfully created Cohort');
      })
      .catch((error) => toast.error(`Error creating Group, ${error?.response?.data?.message}`));
  };
}
export function fetchAllCohorts() {
  return async function (dispatch) {
    return api.get('/groups').then(({ data }) => {
      dispatch({
        type: GroupActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchActiveCohorts() {
  return async function (dispatch) {
    return api.get('/groups/active').then(({ data }) => {
      dispatch({
        type: GroupActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchUserGroups(userId) {
  return async function (dispatch) {
    return api.get(`/groups/userGroups/${userId}`).then(({ data }) => {
      dispatch({
        type: GroupActionTypes.FETCH_ACTIVE,
        payload: data
      });
    });
  };
}

export function updateGroup(group) {
  return function (dispatch) {
    return api.patch('/groups', group).then(({ data }) => {
      toast.success('Successfully updated cohort');
      dispatch({
        type: GroupActionTypes.UPDATE,
        payload: data
      });
    });
  };
}

export function deleteCohort(cohortId: string) {
  return function (dispatch) {
    const url = `groups/${cohortId}`;
    return api
      .delete(url)
      .then(() => {
        dispatch({
          type: GroupActionTypes.DELETE,
          payload: cohortId
        });
        toast.success('Successfully deleted cohort');
      })
      .catch((error) => {
        console.error('Error deleting cohort', error);
        toast.error(
          `Error deleting cohort.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export const getGroups = (state) => state.groups.groups;
export const getActiveGroups = (state) => state.groups.groups.filter((g) => GroupStatus.Active === g.status);