import * as React from 'react';
import { Box, Typography } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { CoachingSessionStatus, Colors, GroupStatus, UserStatus } from '../../../enums/enums';
import { IGroup, IUser } from '../../../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSessionsById, getUserSessions } from '../../../redux/actions/coachingSessions';

interface Props {
  user: IUser;
  cohorts: IGroup[];
}

export default function UserCohortsAndSessions({ user, cohorts }: Props) {
  const dispatch = useDispatch();
  const userSessions = useSelector(getUserSessions);

  React.useEffect(() => {
    if (user?.status === UserStatus.Active || user?.status === UserStatus.Inactive) {
      dispatch(fetchUserSessionsById(user?.id));
    }
  }, [user]);

  const firstName = user?.name?.split(' ')[0];
  const userActiveCohorts = cohorts?.filter((c) => c?.status === GroupStatus.Active);
  const userInactiveCohorts = cohorts?.filter((c) => c?.status === GroupStatus.Inactive);
  const activeCohortLeader = cohorts?.filter((c) => c?.status === GroupStatus.Active && c?.leaderId === user?.id);

  const activeCohortCoLeader = cohorts?.filter((c) => c?.status === GroupStatus.Active && c?.coLeaderId === user?.id);
  const inactiveCohortLeader = cohorts?.filter((c) => c?.status === GroupStatus.Inactive && c?.leaderId === user?.id);
  const inactiveCohortCoLeader = cohorts?.filter(
    (c) => c?.status === GroupStatus.Inactive && c?.coLeaderId === user?.id
  );

  return (
    <Box sx={{ pl: 3, pt: 2, pb: 2, boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 3px 0px', width: '450px' }}>
      {!!activeCohortLeader?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Active Cohorts where {user?.name?.split(' ')[0]} is a Leader:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {activeCohortLeader?.map((cohort: { description: string }) => (
                <li>
                  <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                    <SupervisedUserCircleIcon sx={{ color: Colors.TextElevated, mr: 1 }} /> {cohort.description}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </>
      )}

      {!!activeCohortCoLeader?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Active Cohorts where {firstName} is a Co-Leader:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {activeCohortCoLeader?.map((cohort: { description: string }) => (
                <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                  <SupervisedUserCircleIcon sx={{ color: Colors.TextElevated, mr: 1 }} /> {cohort?.description}
                </Typography>
              ))}
            </ul>
          </Box>
        </>
      )}

      {!!inactiveCohortLeader?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Inactive Cohorts where {firstName} is a Leader:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {inactiveCohortLeader?.map((cohort: { description: string }) => (
                <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                  <SupervisedUserCircleIcon sx={{ color: 'grey', mr: 1 }} /> {cohort?.description}
                </Typography>
              ))}
            </ul>
          </Box>
        </>
      )}

      {!!inactiveCohortCoLeader?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Inactive Cohorts where {firstName} is a Co-Leader:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {inactiveCohortCoLeader?.map((cohort: { description: string }) => (
                <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                  <SupervisedUserCircleIcon sx={{ color: 'grey', mr: 1 }} /> {cohort?.description}
                </Typography>
              ))}
            </ul>
          </Box>
        </>
      )}

      {!!userActiveCohorts?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Active Cohorts where {firstName} is a Participant:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {userActiveCohorts?.map((cohort: { description: string }) => (
                <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                  <SupervisedUserCircleIcon sx={{ color: Colors.TextElevated, mr: 1 }} /> {cohort?.description}
                </Typography>
              ))}
            </ul>
          </Box>
        </>
      )}

      {!!userInactiveCohorts?.length && (
        <>
          <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>
            Inactive Cohorts where {firstName} is a Participant:
          </Typography>
          <Box>
            <ul
              style={{
                listStyleType: 'none'
              }}
            >
              {userInactiveCohorts?.map((cohort: { description: string }) => (
                <Typography sx={{ display: 'flex', alignItems: 'centre', mb: 1 }}>
                  <SupervisedUserCircleIcon sx={{ color: 'grey', mr: 1 }} /> {cohort?.description}
                </Typography>
              ))}
            </ul>
          </Box>
        </>
      )}

      <>
        <Typography sx={{ color: Colors.TextElevated, mb: 1 }}>Sessions:</Typography>
        <Typography sx={{ ml: 5 }}>
          <Typography>
            <b>Total number:</b> {userSessions?.length}
          </Typography>
          <Typography sx={{ ml: 2 }}>
            Confirmed: {userSessions?.filter((s) => s?.status === CoachingSessionStatus.Confirmed)?.length}
          </Typography>
          <Typography sx={{ ml: 2 }}>
            Completed: {userSessions?.filter((s) => s?.status === CoachingSessionStatus.Completed)?.length}
          </Typography>
          <Typography sx={{ ml: 2 }}>
            Cancelled: {userSessions?.filter((s) => s?.status === CoachingSessionStatus.Cancelled)?.length}
          </Typography>
        </Typography>
      </>
    </Box>
  );
}