import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Stack, Typography, TextField } from '@mui/material';
import { SessionStage, SessionStatus } from '../coaching.enums';
import { SESSION_DURATIONS } from '../coaching.constants';
import { toast } from 'react-toastify';
import { SessionDurationInterface } from '../coaching.interface';
import { createIndividualSession, fetchAllCoachingSessions } from '../../../../../redux/actions/coachingSessions';
import { IGroup, IUser } from '../../../../../interfaces/interfaces';
import { Colors } from '../../../../../enums/enums';
import { ICoachingSession } from '../coaching.models';
import * as React from 'react';

interface Props {
  users: IUser[];
  groups: IGroup[];
  sessions: ICoachingSession[];
  openIndividualDialog: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const CreateIndividualSessionEventForm = (props: Props) => {
  const dispatch = useDispatch();
  const sessions = props?.sessions;
  const users = props?.users;
  const groups = props?.groups;
  const openDialog = props?.openIndividualDialog;
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezones = moment.tz.names();
  const time = (zone) => moment.tz(zone).format('Z');
  const [tz, setTz] = useState({ timezone: currentTimezone, name: currentTimezone });
  const [groupId, setGroupId] = useState('');
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [groupSessions, setGroupSessions] = useState([]);

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      group: null,
      participant: null,
      date: '',
      sessionDuration: SESSION_DURATIONS[0],
      timezone: { timezone: currentTimezone, name: currentTimezone }
    }
  });

  useEffect(() => {
    setGroupSessions(sessions?.filter((s) => s.groupId === groupId));
  }, [groupId]);

  const onSubmit = async (data: any) => {
    try {
      const values = getValues();
      const selectedTimezone = moment.tz(values.date, tz.timezone);
      const vancouverTimezone = selectedTimezone.clone().tz('America/Vancouver');
      const finalSelectedTime = vancouverTimezone.toString();
      const leaderName = users?.find((u) => u.id === values?.group.leaderId)?.name;
      const participantName = values?.participant?.name;

      toast.info('Request submitted, please wait...');

      const session = {
        groupId: values.group.id,
        participantId: values.participant.id,
        stage: SessionStage.Individual,
        status: 'CONFIRMED',
        date: finalSelectedTime,
        topic: `1:1 Session ${leaderName} <> ${participantName}`,
        sessionDuration: values.sessionDuration,
        token: '',
        url: ''
      };
      console.log('PAYLOAD INDIVIDUAL SESSION>>', session);
      dispatch(createIndividualSession(session));
      dispatch(fetchAllCoachingSessions());
      props.onClose(!openDialog);
      reset();
      setGroupSessions([]);
      setGroupId('');
      setGroupParticipants([]);
    } catch (error) {
      toast.error(`Error creating individual session.\nMessage: ${error?.message}`);
    }
  };

  return (
    <Dialog
      onClose={() => {
        props.onClose(!openDialog);
        setGroupSessions([]);
        setGroupId('');
        setGroupParticipants([]);
        reset();
      }}
      open={openDialog}
      maxWidth="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id="simple-dialog-title"
          style={{ backgroundColor: Colors.BackgroundDrawer, color: Colors.TextElevated }}
        >
          Create 1:1 Session within a Group
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: Colors.TextElevated, mb: 4, mt: 1 }}>
            You are creating an Individual Session for one participant of a selected cohort
          </Typography>
          <Stack direction="row" spacing={2}>
            <DialogContent>
              <div style={{ marginBottom: 25 }}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="group"
                      onChange={(event, newInputValue: { id: string; name: string }) => {
                        field.onChange(newInputValue);
                        const selectedGroup = groups?.find((g) => g.id === newInputValue?.id);
                        setGroupParticipants(users?.filter((u) => selectedGroup?.participantIds?.includes(u.id)));
                        setGroupId(newInputValue?.id);
                      }}
                      options={groups}
                      getOptionLabel={(option: { id: string; description: string }) => option.description}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Cohort"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                  name="group"
                  control={control}
                />
              </div>

              <>
                <Box display="block">
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="participant"
                        onChange={(event, newInputValue: { id: string; name: string }) => {
                          field.onChange(newInputValue);
                        }}
                        options={groupParticipants}
                        getOptionLabel={(option: { id: string; name: string }) => option.name}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Participant"
                            variant="outlined"
                            color="success"
                            required={true}
                          />
                        )}
                      />
                    )}
                    name="participant"
                    control={control}
                  />
                </Box>
              </>
              {groupId && (
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ color: Colors.TextElevated }}>Cohort's booked sessions:</Typography>
                  {groupSessions?.map((session) => (
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        minWidth: '180px',
                        mt: 1
                      }}
                      key={session?.id}
                    >
                      {session?.stage} :{' '}
                      {new Date(session?.date)?.toLocaleString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Typography>
                  ))}
                </Box>
              )}
            </DialogContent>

            <DialogContent>
              {/* Date picker */}
              <DialogContent style={{ marginTop: -12 }}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="datetime-local"
                      label="Date"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      style={{ width: 300, height: '56px', paddingTop: '5px', marginBottom: '10px' }}
                      variant="outlined"
                      color="success"
                      required={true}
                    />
                  )}
                  name="date"
                  control={control}
                  rules={{ required: true }}
                />
              </DialogContent>

              {/* Session Duration*/}
              <DialogContent style={{ marginTop: -10, marginBottom: 5 }}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="sessionDuration"
                      onChange={(event, newInputValue: SessionDurationInterface) => {
                        field.onChange(newInputValue);
                      }}
                      options={SESSION_DURATIONS}
                      getOptionLabel={(option: SessionDurationInterface) => option.label}
                      style={{ width: 300, marginTop: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Session Duration"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                  name="sessionDuration"
                  control={control}
                />
              </DialogContent>

              {/* Timezone picker */}
              <DialogContent>
                <Autocomplete
                  id="timezone"
                  onChange={(event, newInputValue: { timezone: string; name: string }) => setTz(newInputValue)}
                  defaultValue={tz}
                  options={timezones.map((v) => {
                    let obj = { timezone: '', name: '' };
                    obj.timezone = v;
                    obj.name = `${time(v)} - ${v}`;
                    return obj;
                  })}
                  getOptionLabel={(option: { timezone: string; name: string }) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Timezone"
                      variant="outlined"
                      color="success"
                    />
                  )}
                />
              </DialogContent>
            </DialogContent>
          </Stack>
        </DialogContent>
        <DialogActions style={{ backgroundColor: Colors.BackgroundMain, marginTop: 30 }}>
          <Button
            style={{ float: 'right', color: 'grey', margin: '20px' }}
            size="large"
            color="inherit"
            variant="outlined"
            onClick={() => {
              props.onClose(!openDialog);
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ float: 'right', backgroundColor: Colors.ButtonGreen, margin: '20px' }}
            size="large"
            color="success"
            variant="contained"
            type="submit"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateIndividualSessionEventForm;