import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Chip,
  Box,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  DialogActions
} from '@mui/material';
import { fetchAllCohorts, getGroups, updateGroup } from '../../../../redux/actions/groups';
import { format, parseISO } from 'date-fns';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { MenuItem } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Colors, GroupStatus, UserStatus } from '../../../../enums/enums';
import { Link } from 'react-router-dom';
import { IGroup, IUser } from '../../../../interfaces/interfaces';
import IClient from '../clients/IClient';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteCohortDialog from './DeleteCohortDialog';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface Props {
  users: IUser[];
  clients: IClient[];
}

const ActiveCohortsTable = ({ users, clients }: Props) => {
  const dispatch = useDispatch();
  const groups: IGroup[] = useSelector(getGroups);
  const activeGroups = groups?.filter((g) => g?.status === GroupStatus.Active);
  const activeUsers = users?.filter((user) => user.status === UserStatus.Active);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingParticipants, setEditingParticipants] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newLeaderId, setNewLeaderId] = useState(null);
  const [newCoLeaderId, setNewCoLeaderId] = useState(null);
  const [newParticipantIds, setNewParticipantIds] = useState(null);
  const statusColors = { ACTIVE: Colors.Active, INACTIVE: Colors.Inactive };
  const [client, setClient] = useState(null);
  const [activeGroupsClientFilter, setActiveGroupsClientFilter] = useState('All Clients');
  const [activeParticipantsNumberFilter, setActiveParticipantsNumberFilter] = useState('All Cohorts');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openDeleteCohortDialog, setOpenDeleteCohortDialog] = useState(false);
  const [cohortId, setCohortId] = useState(null);

  /**
   * Need to wrap `groups` data into the state variable - so that
   * will be able to update these data programmatically.
   */
  const [activeTableData, setActiveTableData] = useState([]);

  const { handleSubmit, getValues, setValue, control, reset } = useForm({
    defaultValues: {
      groupId: null,
      client: null,
      participants: []
    }
  });

  useEffect(() => {
    dispatch(fetchAllCohorts());
  }, [dispatch]);

  useEffect(() => {
    if (activeGroupsClientFilter === 'All Clients') {
      let newFilteredData = activeGroups;

      if (activeParticipantsNumberFilter === 'Group Cohorts') {
        const filteredByParticipant = newFilteredData?.filter(
          (group) => group.participantIds.split(',').length > 1 && group.status === GroupStatus.Active
        );
        newFilteredData = filteredByParticipant;
      }

      if (activeParticipantsNumberFilter === '1:1 Cohorts') {
        const filteredByParticipant = activeGroups?.filter(
          (group) => group.participantIds.split(',').length == 1 && group.status === GroupStatus.Active
        );
        newFilteredData = filteredByParticipant;
      }

      setActiveTableData(newFilteredData);
    }

    if (activeGroupsClientFilter !== 'All Clients') {
      let newFilteredData = activeGroups;
      const findUserClientId = (userIds: string[]): boolean => {
        const groupUsers = users?.filter((user) => userIds?.includes(user?.id));
        const clientFound = groupUsers?.some((user) => user.clientId === activeGroupsClientFilter);
        return clientFound;
      };

      const filteredByClient = groups?.filter(
        (group) => findUserClientId(group?.participantIds) && group.status === GroupStatus.Active
      );
      newFilteredData = filteredByClient;

      if (activeParticipantsNumberFilter === 'Group Cohorts') {
        const filteredByParticipant = newFilteredData?.filter(
          (group) => group.participantIds.split(',').length > 1 && group.status === GroupStatus.Active
        );
        newFilteredData = filteredByParticipant;
      }

      if (activeParticipantsNumberFilter === '1:1 Cohorts') {
        const filteredByParticipant = newFilteredData?.filter(
          (group) => group.participantIds.split(',').length == 1 && group.status === GroupStatus.Active
        );
        newFilteredData = filteredByParticipant;
      }

      if (activeParticipantsNumberFilter === 'All Cohorts') {
        const filteredByParticipant = filteredByClient?.filter((group) => group.status === GroupStatus.Active);
        newFilteredData = filteredByParticipant;
      }

      setActiveTableData(newFilteredData);
    }
  }, [dispatch, activeGroupsClientFilter, activeParticipantsNumberFilter, groups]);

  useEffect(() => {
    if (client) {
      const usersByClient = users?.filter((user) => user?.clientId === client?.id);
      setFilteredUsers(usersByClient);
    }
  }, [client]);

  const userOptions = activeUsers?.map((option) => {
    const clientName = clients?.find((client) => client.id === option.clientId)?.name?.toUpperCase();
    return {
      clientName: clientName,
      ...option
    };
  });

  const handleChangeStatus = (event) => {
    setNewStatus(event?.target?.value as string);
  };
  const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewDescription(event?.target?.value as string);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'description',
      label: 'Cohort Name',
      options: {
        customBodyRender: (v, x) =>
          editing && x?.rowIndex === selectedRow ? (
            <TextField
              label="Cohort Name"
              placeholder="enter cohort name"
              sx={{ minWidth: '270px' }}
              value={newDescription || v}
              onChange={handleDescriptionChange}
            />
          ) : (
            <Typography sx={{ minWidth: '270px' }}>{v}</Typography>
          )
      }
    },
    {
      name: 'leaderId',
      label: 'Leader | Co-Leader',
      options: {
        customBodyRender: (v, x) => {
          const leader = users?.find((user) => user?.id === v);
          const coLeader = users?.find((user) => user?.id === x?.rowData[7]);
          return editing && x?.rowIndex === selectedRow ? (
            <Box>
              <Autocomplete
                id="leaderId"
                onChange={(
                  event,
                  newInputValue: {
                    id: string;
                    name: string;
                    company: string;
                    clientName: string;
                  }
                ) => {
                  setNewLeaderId(newInputValue?.id);
                }}
                options={userOptions
                  .filter(
                    (userOption) =>
                      userOption.id !== coLeader?.id &&
                      userOption.id !== newCoLeaderId &&
                      !x?.rowData[3]?.includes(userOption.id)
                  )
                  .sort((a, b) => -b.clientName?.localeCompare(a.clientName))}
                groupBy={(option) => option.clientName}
                getOptionLabel={(option: { id: string; name: string; company: string; clientName: string }) =>
                  `${option.name} ${
                    option.company?.toUpperCase() === option.clientName
                      ? ''
                      : option.company === null
                      ? ' - [add company info]'
                      : ` - ${option.company}`
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '400px', color: Colors.TextElevated, mb: 2 }}
                    placeholder={`${leader?.name} - ${leader?.company}`}
                    label="Leader Name"
                    variant="outlined"
                    color="success"
                    required={true}
                  />
                )}
              />

              <Autocomplete
                id="coLeader"
                onChange={(
                  event,
                  newInputValue: {
                    id: string;
                    name: string;
                    company: string;
                    clientName: string;
                  }
                ) => {
                  setNewCoLeaderId(newInputValue?.id);
                }}
                options={userOptions
                  .filter(
                    (userOption) =>
                      userOption.id !== leader?.id &&
                      userOption.id !== newLeaderId &&
                      !x?.rowData[3]?.includes(userOption.id)
                  )
                  .sort((a, b) => -b.clientName?.localeCompare(a.clientName))}
                groupBy={(option) => option.clientName}
                getOptionLabel={(option: { id: string; name: string; company: string; clientName: string }) =>
                  `${option.name} - ${option.company || '[add company name]'}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '400px' }}
                    placeholder={coLeader ? `${coLeader?.name} - ${coLeader?.company}` : 'select a co-leader'}
                    label="Co-Leader Name"
                    variant="outlined"
                    color="success"
                  />
                )}
              />
            </Box>
          ) : (
            <Box sx={{ minWidth: '200px', ml: -2 }}>
              <Typography sx={{ display: 'flex', justifyContent: 'start' }}>
                <a href={leader?.linkedin} target="_blank">
                  <LinkedInIcon color={leader?.linkedin ? 'primary' : 'disabled'} style={{ marginRight: 5 }} />
                </a>
                {leader?.name}
              </Typography>
              {coLeader && (
                <Typography sx={{ display: 'flex', justifyContent: 'start', color: 'grey' }}>
                  <a href={coLeader?.linkedin} target="_blank">
                    <LinkedInIcon color={coLeader?.linkedin ? 'primary' : 'disabled'} style={{ marginRight: 5 }} />
                  </a>
                  Co-Leader: {coLeader?.name}
                </Typography>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'participantIds',
      label: 'Participants',
      options: {
        customBodyRender: (v, x) => {
          const participants = users?.filter((user) => v?.includes(user?.id));
          return (
            <>
              {participants?.map((user) => (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    cursor: editing && x?.rowIndex === selectedRow ? 'pointer' : 'default',
                    minWidth: '180px',
                    mx: -2
                  }}
                  key={user.id}
                  onClick={() => {
                    if (editing && x?.rowIndex === selectedRow) {
                      reset({
                        groupId: x?.rowData[0],
                        client: null,
                        participants: participants
                      });
                      setEditingParticipants(true);
                    }
                  }}
                >
                  <TaskAltIcon
                    fontSize="small"
                    style={{ marginRight: 5, color: user?.status === UserStatus.Active ? 'green' : 'lightgrey' }}
                  />
                  {user?.name}
                </Typography>
              ))}
            </>
          );
        }
      }
    },
    {
      name: 'participantIds',
      label: 'Clients',
      options: {
        filterList: [],
        customBodyRender: (v, x) => {
          const participants = users.filter((user) => v?.includes(user.id));
          return (
            <>
              {participants?.map((user) => (
                <Typography
                  sx={{
                    color: Colors.TextElevated,
                    minWidth: '180px',
                    ml: -2,
                    mr: -4
                  }}
                  key={user.id}
                >
                  {clients?.find((c) => c?.id === user?.clientId)?.name}
                </Typography>
              ))}
            </>
          );
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (v, x) =>
          editing && x.rowIndex === selectedRow ? (
            <Select value={newStatus || v} onChange={handleChangeStatus}>
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
            </Select>
          ) : (
            <Chip
              style={{ backgroundColor: statusColors[v], color: 'black' }}
              label={v}
              onClick={() => console.log(x)}
            />
          )
      }
    },
    {
      name: 'createdAt',
      label: 'Creation Date',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy')?.toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'coLeaderId',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: 'View Responses',
      options: {
        customBodyRender: (v, x) => (
          <Link to={`/responses/${x?.rowData[1]}/${v}`} style={{ textDecoration: 'none' }} target="_blank">
            <Button variant="contained" color="success" sx={{ backgroundColor: Colors.TextElevated }}>
              Responses
            </Button>
          </Link>
        )
      }
    },
    {
      name: 'id',
      label: 'View Sessions',
      options: {
        customBodyRender: (v, x) => (
          <Link
            to={`/coaching-sessions-list-filtered/${x?.rowData[1]}/${v}`}
            style={{ textDecoration: 'none' }}
            target="_blank"
          >
            <Button variant="contained" color="success" sx={{ backgroundColor: Colors.MenuText }}>
              Sessions
            </Button>
          </Link>
        )
      }
    },
    {
      name: 'id',
      label: 'Edit Cohort',
      options: {
        customBodyRender: (v, x) => (
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              setSelectedRow(x?.rowIndex);
              setEditing(!editing);
              setNewStatus(x?.rowData[5]);
              setNewDescription(x?.rowData[1]);
            }}
          >
            {editing && x?.rowIndex === selectedRow ? <span style={{ color: 'cornflowerblue' }}>Cancel</span> : 'Edit'}
          </Button>
        )
      }
    },
    {
      name: 'id',
      label: 'Delete Cohort',
      options: {
        customBodyRender: (v, x) => (
          <Button
            variant="outlined"
            color="error"
            disabled={!!editing}
            onClick={() => {
              setOpenDeleteCohortDialog(true);
              setCohortId(v);
            }}
          >
            Delete
          </Button>
        )
      }
    }
  ];

  if (editing) {
    columns.push({
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (v, x) =>
          x?.rowIndex === selectedRow && (
            <Button
              variant="outlined"
              color="success"
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                dispatch(
                  updateGroup({
                    id: v,
                    status: newStatus,
                    description: newDescription,
                    participantIds: newParticipantIds,
                    leaderId: newLeaderId,
                    coLeaderId: newCoLeaderId
                  })
                );
                setEditing(false);
                setNewStatus('');
                setNewDescription('');
                setNewParticipantIds(null);
                setNewLeaderId(null);
                setNewCoLeaderId(null);
                setClient(null);
              }}
            >
              <span>Save</span>
            </Button>
          )
      }
    });
  }
  const options = {
    filter: true,
    filterType: 'dropdown',
    selectableRows: false
  };

  const onChangeParticipantsFormSubmit = (data) => {
    console.log('onChangeParticipantsFormSubmit() form data>>>', data);
    const { participants, groupId } = data;

    const participantIds = participants?.map((user) => user?.id);
    let participantIdsString = '';
    if (participantIds) {
      participantIdsString = participantIds?.join(',');
    }
    setNewParticipantIds(participantIdsString);
    setEditingParticipants(false);

    if (groupId) {
      const updatedActiveGroups = activeTableData?.map((group) => {
        if (group?.id === groupId) {
          group.participantIds = participantIds;
        }
        return group;
      });
      setActiveTableData(updatedActiveGroups);
    }
  };

  return (
    <>
      <MUIDataTable
        title={
          <Stack direction="row" spacing={5} sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <h2 style={{ color: Colors.TextElevated }}>Active Cohorts</h2>
            <FormControl sx={{ width: '250px' }} variant="outlined">
              <InputLabel id="clients">Filter by Client</InputLabel>
              <Select
                sx={{ height: '45px' }}
                label="Filter by Client"
                value={activeGroupsClientFilter}
                onChange={(event) => {
                  setActiveTableData(activeGroups);
                  setActiveGroupsClientFilter(event?.target?.value);
                }}
              >
                <MenuItem key={1} value="All Clients">
                  All Clients
                </MenuItem>
                {clients?.map((client) => (
                  <MenuItem key={client?.id} value={client?.id}>
                    {client?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: '250px' }} variant="outlined">
              <InputLabel id="participants">Filter by Number of Participants</InputLabel>
              <Select
                sx={{ height: '45px' }}
                label="Filter by Number of Participants"
                value={activeParticipantsNumberFilter}
                onChange={(event) => {
                  setActiveTableData(activeGroups);
                  setActiveParticipantsNumberFilter(event?.target?.value);
                }}
              >
                <MenuItem key={1} value="All Cohorts">
                  All Cohorts
                </MenuItem>
                <MenuItem key={2} value="Group Cohorts">
                  Group Cohorts
                </MenuItem>
                <MenuItem key={3} value="1:1 Cohorts">
                  1:1 Cohorts
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        }
        editable={true}
        data={activeTableData}
        columns={columns}
        options={options}
      />

      {/*Edit Cohort*/}
      <Dialog
        onClose={() => {
          setEditingParticipants(false);
          reset();
          setClient(null);
          setNewParticipantIds(null);
          setFilteredUsers([]);
        }}
        open={editingParticipants}
      >
        <form onSubmit={handleSubmit(onChangeParticipantsFormSubmit)}>
          <DialogTitle sx={{ backgroundColor: Colors.BackgroundDrawer, color: Colors.TextElevated, mb: 2 }}>
            Edit Cohort Participants
          </DialogTitle>

          <DialogContent>
            <Stack direction="column" spacing={4} sx={{ mb: 0, mt: 2 }}>
              <Controller
                name="client"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="clients"
                    onChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                      setClient(newInputValue);
                    }}
                    options={clients}
                    getOptionLabel={(option: { name: string }) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{ width: '270px' }}
                        label="Client"
                        variant="outlined"
                        color="secondary"
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="participants"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined">
                    <InputLabel shrink={true} id="user-label">
                      Users *
                    </InputLabel>
                    <Select
                      labelId="user-label-id"
                      multiple
                      sx={{ width: '500px' }}
                      notched={true}
                      required={true}
                      input={<OutlinedInput id="user-label" label="Users *" />}
                      {...field}
                      onChange={(event, addedUser: any) => {
                        const addedUserId = addedUser.props?.value?.id;
                        if (!getValues('participants')?.find((user) => user?.id === addedUserId)) {
                          field.onChange(event);
                        }
                      }}
                      renderValue={(selectedUsers) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selectedUsers.map((user) => (
                            <Chip
                              key={user.id}
                              label={user.name}
                              onDelete={() => {
                                const filterUsers = getValues('participants')?.filter((u) => u.id !== user?.id);
                                setValue('participants', filterUsers);
                              }}
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {filteredUsers?.map((user) => (
                        <MenuItem key={user?.id} value={user}>
                          {user?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </DialogContent>
          <Typography sx={{ mt: 2, ml: 3, mb: 3 }}>
            Click SAVE button when this dialog closes to save your changes
          </Typography>
          <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer }}>
            <Button variant="contained" color="success" type="submit" sx={{ height: 45, width: 110, m: 2 }}>
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <DeleteCohortDialog
        cohortId={cohortId}
        open={openDeleteCohortDialog}
        onClose={() => {
          setOpenDeleteCohortDialog(false);
          setCohortId(null);
        }}
      />
    </>
  );
};

export default ActiveCohortsTable;