import React, { Dispatch, SetStateAction } from 'react';
import { Button, DialogContent, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { IUser } from '../../../interfaces/interfaces';
import { declineUser } from '../../../redux/actions/users';
import { Colors } from '../../../enums/enums';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

interface Props {
  open: boolean;
  user: IUser;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const DeclineUserDialog = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open, user, onClose } = props;

  const {
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = () => {
    try {
      dispatch(declineUser(user?.id));
      handleClose();
      history.push('/');
    } catch (e) {
      console.error('Error declining user: ', e);
    }
  };

  const handleClose = () => {
    onClose(!open);
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ color: '#bd0c0c', backgroundColor: Colors.Inactive }}>
          Decline User Registration?
        </DialogTitle>
        <DialogContent sx={{ width: '700px', height: '250px', backgroundColor: Colors.BackgroundMain, mt: 3 }}>
          <Typography sx={{ my: 2, fontSize: '1.1rem', fontWeight: 'bold' }}>CAUTION:</Typography>
          <Typography gutterBottom>
            Are you sure you want to decline registering {user?.name} as a new user?
          </Typography>
          <Typography>All data related to this user will be removed from the database.</Typography>
          <Typography>
            {user?.name} will not receive any emails or further instructions and can register in future
            again using the same email address.
          </Typography>
        </DialogContent>

        <DialogActions style={{ backgroundColor: Colors.Inactive }}>
          <>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: 'grey', mr: 2, mb: 2, mt: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="error" sx={{ mr: 2, mb: 2, mt: 2 }} type="submit">
              Decline User
            </Button>
          </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeclineUserDialog;