import { Rating, Typography } from '@mui/material';
import React from 'react';

const finalGroupColumns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{v}</Typography>
            <Typography fontSize="small">{x?.rowData[3]}</Typography>
            <Typography fontSize="small">
              {x?.rowData[7]} at {x?.rowData[8]}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'groupRating',
    label: 'Ratings*',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">Group sessions: </Typography>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
            <Typography fontSize="small">Private Sessions: </Typography>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(x?.rowData[4])} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'email',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'privateRating',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherGainedInsights',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherReferProgram',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'skillsGained',
    label: 'Skills Gained*',
    options: {
      hint: 'What specific skills or knowledge did you gain from the leadership program?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '150px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'examples',
    label: 'Growth Examples*',
    options: {
      hint: 'Can you provide examples of specific challenges or situations where the leadership program helped you make better decisions or achieve positive outcomes?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '180px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'changes',
    label: 'Leadership Style Changes*',
    options: {
      hint: 'Have you noticed any changes in your leadership style or approach as a result of the program? If yes, please elaborate.',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '180px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'valuableAspects',
    label: 'Program Valuable Aspects*',
    options: {
      hint: 'What aspects of the leadership program did you find most valuable or impactful?',
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -35, marginRight: -30 }}>
          {v?.map((valuableAspect) => (
            <li>
              <Typography fontSize="small" sx={{ width: '150px' }}>
                {valuableAspect}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'gainedInsights',
    label: 'Areas Where Got New Knowledge*',
    options: {
      hint: 'In what areas do you feel you have learned something new or have gained insights to improve?',
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -35 }}>
          {v?.map((gainedInsight) => (
            <li>
              <Typography fontSize="small" sx={{ width: '150px' }}>
                {gainedInsight === 'Other' ? x?.rowData[5] : gainedInsight}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'betterLeader',
    label: 'You Became a Better Leader?*',
    options: {
      hint: 'Do you think you are a better leader than you were before the program?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'referProgram',
    label: 'Refer Program to Colleagues?*',
    options: {
      hint: 'Would you refer this program to your colleagues',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v === 'Other' ? x?.rowData[6] : v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'improvement',
    label: 'BestHuman Improvement',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'attendWorkshops',
    label: 'Will Attend Workshops',
    options: {
      hint: 'Would you like to attend virtual monthly leadership topics workshops led by experts?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'testimonial',
    label: 'Testimonial',
    options: {
      hint: 'Do you have a testimonial you would like to share about your Coach and overall experience',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'portalImprovement',
    label: 'Portal Improvement',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  }
];

export default finalGroupColumns;