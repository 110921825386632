import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCoachingSessions, getCoachingSessions } from '../../../redux/actions/coachingSessions';
import { fetchAllCohorts, getGroups } from '../../../redux/actions/groups';
import { CoachingSessionStatus } from '../../../enums/enums';
import { SessionStage } from '../mentorship/coaching-sessions/coaching.enums';

const SessionsCalendarDashboard = () => {
  const API_KEY = 'AIzaSyCm1eCMpW_MIGvxr-bL0ZBetF_ovLffjjw';
  const calendarId = 'c_so8rlv4u5fmp12nfg20f07h8dk@group.calendar.google.com';

  const dispatch = useDispatch();
  const calendarComponentRef = React.createRef<any>();
  const sessions = useSelector(getCoachingSessions);
  const groups = useSelector(getGroups);

  const [calendarEvents, setCalendarEvents] = useState(sessions);

  const [edit, setEdit] = useState(false);
  const [createSessionEvent, setCreateSessionEvent] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    dispatch(fetchAllCoachingSessions());
    dispatch(fetchAllCohorts());
  }, []);

  useEffect(() => {
    if (sessions) {
      setCalendarEvents(sessions);
    }
  }, [sessions]);

  const gotoPast = () => {
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
  };

  const handleDateClick = (arg: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
      let newEl = {
        title: 'New Event',
        start: arg?.date,
        allDay: arg?.allDay
      };
      setCalendarEvents([newEl, ...calendarEvents]);
    }
  };

  const formatEvents = (list: any[]) => {
    return list
      .filter((session) => session?.status !== CoachingSessionStatus.Cancelled)
      .map(
        (item: {
          id: any;
          topic: any;
          stage: string;
          date: string;
          start: { dateTime: any; date: any };
          end: { dateTime: any; date: any };
          status: string;
          duration: number;
          groupId: string;
        }) => ({
          id: item?.id,
          title: `${groups?.find((g) => g.id === item?.groupId)?.description} : ${
            item?.stage === SessionStage.MeetAndGreet ? 'First Session' : item?.stage
          } ${item.topic ? ' - ' + item?.topic : ''}`,
          start: item.date,
          end: moment(item.date).add(item?.duration, 'minutes').toDate(),
          status: item?.status,
          groupId: item?.groupId,
          color: item?.status === CoachingSessionStatus.Confirmed ? 'green' : 'grey'
        })
      );
  };

  return (
    <div>
      <FullCalendar
        height={330}
        //  contentHeight='200px'
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin, listPlugin]}
        // customButtons={{
        //   myCustomButton: {
        //       text: `Create Session`,
        //       click: () => setCreateSessionEvent(true),
        //   },
        // }}
        // headerToolbar={{
        //   left: "prev,next today myCustomButton",
        //   center: "title",
        //   right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
        // }}

        headerToolbar={false}
        initialView="listWeek"
        ref={calendarComponentRef}
        weekends={false}
        events={formatEvents(calendarEvents)}
        dateClick={(e) => {
          setCreateSessionEvent(true);
        }}
        eventClick={(e) => {
          setCurrentEvent(e.event);
          setEdit(!edit);
          console.log(e.jsEvent, e.event);
          return;
        }}
        eventMouseEnter={(e) => console.log(e)}
      />
    </div>
  );
};

export default SessionsCalendarDashboard;