import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CreateCoachingSessionEventForm from './CreateCoachingSessionEventForm';
import {
  fetchAllCoachingSessions,
  getCoachingSessions,
  updateCoachingSession
} from '../../../../../redux/actions/coachingSessions';
import EditCoachingSessionEventForm from './EditCoachingSessionEventForm';
import { fetchAllCohorts, getGroups } from '../../../../../redux/actions/groups';
import { SessionStage } from '../coaching.enums';

const FullCalendarCoachingSessions = () => {
  const dispatch = useDispatch();
  const calendarComponentRef = React.createRef<any>();
  const sessions = useSelector(getCoachingSessions);
  const groups = useSelector(getGroups);
  const [calendarEvents, setCalendarEvents] = useState(sessions);
  const [edit, setEdit] = useState(false);
  const [createCoachingSessionEvent, setCreateCoachingSessionEvent] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    console.log('COACHING SESSIONS >>>', sessions);
    dispatch(fetchAllCoachingSessions());
    dispatch(fetchAllCohorts());
  }, [dispatch]);

  useEffect(() => {
    if (sessions) {
      setCalendarEvents(sessions);
    }
  }, [sessions]);

  const updateSessionDate = async ({ id, date }) => {
    try {
      await dispatch(updateCoachingSession({ id, date }));
      await dispatch(fetchAllCoachingSessions());
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Sets background color for calendar based on Session's stage value
   * @param stage - stage name
   * @returns - color hex value
   */
  function setStageColor(stage: string): string {
    let color = '#64645b';
    if (stage) {
      switch (stage) {
        case SessionStage.MeetAndGreet:
          color = '#518f5f';
          break;
        case SessionStage.Recurring:
          color = '#90659b';
          break;
        case SessionStage.Final:
          color = '#ab6363';
          break;

        case SessionStage.Individual:
          color = '#698ab9';
          break;
      }
    }
    return color;
  }
  const getGroupName = (groupId: string): string => {
    const group = groups.find((group) => group?.id === groupId);
    return group?.description;
  };
  const formatEvents = (list: any[]) => {
    return list
      .filter((session) => session?.status !== 'CANCELLED')
      .map(
        (item: {
          id: any;
          groupId: any;
          stage: string;
          date: string;
          topic: string;
          start: { dateTime: any; date: any };
          end: { dateTime: any; date: any };
          status: string;
          duration: number;
        }) => ({
          id: item.id,
          title: `${getGroupName(item.groupId)} : ${item.topic || ''} ${
            item.stage === SessionStage.MeetAndGreet ? 'First Session' : item.stage
          }`,
          start: item.date,
          end: moment(item.date)
            .add(item?.duration ? item?.duration : 30, 'minutes')
            .toDate(),
          status: item.status,
          color: setStageColor(item.stage)
        })
      );
  };

  return (
    <div style={{ margin: '20px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin, listPlugin]}
        customButtons={{
          myCustomButton: {
            text: `Create Session`,
            click: () => setCreateCoachingSessionEvent(true)
          }
        }}
        headerToolbar={{
          left: 'prev,next today myCustomButton',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        }}
        // googleCalendarApiKey={API_KEY}
        editable
        eventMinHeight={65}
        contentHeight={1300}
        droppable
        eventDrop={(e) => {
          setCurrentEvent(e.event);
          setEdit(!edit);
        }}
        initialView="timeGridWeek"
        ref={calendarComponentRef}
        weekends={false}
        events={formatEvents(calendarEvents)}
        dateClick={(e) => {
          setCreateCoachingSessionEvent(true);
        }}
        nowIndicator
        eventClick={(e) => {
          setCurrentEvent(e.event);
          setEdit(!edit);
          console.log(e.jsEvent, e.event);
          return;
        }}
        eventMouseEnter={(e) => {}}
      />
      <EditCoachingSessionEventForm
        openDialog={edit}
        onClose={() => setEdit(!edit)}
        event={currentEvent}
        updateSession={updateSessionDate}
      />
      <CreateCoachingSessionEventForm
        groups={groups}
        sessions={sessions}
        openDialog={createCoachingSessionEvent}
        onClose={() => setCreateCoachingSessionEvent(!createCoachingSessionEvent)}
      />
    </div>
  );
};

export default FullCalendarCoachingSessions;