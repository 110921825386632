import * as React from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dispatch, SetStateAction } from 'react';
import { getGroups } from '../../../../../redux/actions/groups';
import { Button, Stack, TextField } from '@mui/material';
import { Colors } from '../../../../../enums/enums';

interface Props {
  selectedGroup: { id: string; description: string };
  filterGroup: Dispatch<SetStateAction<{ id: string; description: string }>>;
  setOpenDialogSession: Dispatch<SetStateAction<boolean>>;
  setOpenIndividualDialogSession: Dispatch<SetStateAction<boolean>>;
}

const CoachingSessionFilter = (props: Props) => {
  const groups = useSelector(getGroups);

  return (
    <Stack direction="row" spacing={4} sx={{ mt: 1 }}>
      <Autocomplete
        id="groups"
        onChange={(event, newInputValue: { id: string; description: string }) => {
          props.filterGroup(newInputValue);
        }}
        value={props.selectedGroup}
        options={groups}
        getOptionLabel={(option: { id: string; description: string }) => option.description}
        style={{ width: 240, height: 60 }}
        renderInput={(params) => (
          <TextField {...params} label="Filter Sessions by Cohort" variant="outlined" color="success" />
        )}
      />
      <Button
        variant="contained"
        color="success"
        type="submit"
        onClick={() => {
          props.setOpenDialogSession(true);
        }}
        sx={{ background: Colors.ButtonGreen, height: '60px', width: '180px', ml: 3 }}
      >
        Create Sessions
      </Button>

      <Button
        variant="contained"
        color="success"
        type="submit"
        onClick={() => {
          props.setOpenIndividualDialogSession(true);
        }}
        sx={{ background: Colors.ButtonGreen, height: '60px', width: '240px', ml: 3 }}
      >
        Create 1:1 within a Group
      </Button>
    </Stack>
  );
};

export default CoachingSessionFilter;