import { AnyAction } from 'redux';
import { GroupActionTypes } from '../actions/groups';
import { UserActionTypes } from '../actions/users';

const initialState = {
  groups: []
};

export function GroupsReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case GroupActionTypes.CREATE:
      return {
        ...state,
        groups: [action.payload, ...state.groups]
      };

    case GroupActionTypes.FETCH:
      return {
        ...state,
        groups: action.payload
      };

      case GroupActionTypes.FETCH_ACTIVE:
      return {
        ...state,
        groups: action.payload
      };

    case GroupActionTypes.UPDATE:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === action.payload.id) {
            group.status = action.payload.status;
            group.description = action.payload.description;
            group.participantIds = action.payload.participantIds;
            group.leaderId = action.payload.leaderId;
            group.coLeaderId = action.payload.coLeaderId;
          }
          return group;
        })
      };

    case GroupActionTypes.DELETE:
      const idOfDeletedCohort = action.payload;
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== idOfDeletedCohort)
      };

    default:
      return state;
  }
}